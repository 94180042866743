import { fetchHubspotDealByProperties } from '@utils/hubspot'
import { IHubspotContact, IHubspotDeal } from 'types/hubspot'
import { COOKIE_HUBSPOT } from '@utils/constants'
import Cookie from 'js-cookie'
import {
  IHubspotLineItem,
  IPlusLineItem,
  IPlusOrder,
  TPlusOrderStatus,
} from 'types/orders'
import { isoDateToReadableDate, isoDateToTimestamp } from '@utils/utilities'
import * as Sentry from '@sentry/nextjs'

const PLUS_FREIGHT_SKUS = ['DDP', 'DDU', 'EXW']

enum newPlusSalesPipelineStages {
  INDICATIVE_QUOTE_SENT = 'qualifiedtobuy',
  INVOICE_SENT = 'presentationscheduled',
  PENDING = '112456970',
  IN_PRODUCTION = '112456971',
  READY_TO_SHIP = '147787187',
  SHIPPED = '112456972',
  DELIVERED = '147810382',
  COMPLETE = '112456973',
}

enum graduationFunnelPipelineStages {
  INDICATIVE_QUOTE_SENT = '50098354',
  INVOICE_SENT = '50098355',
  PENDING = '112450197',
  IN_PRODUCTION = '112450198',
  READY_TO_SHIP = '147798713',
  SHIPPED = '112450199',
  DELIVERED = '147827349',
  COMPLETE = '112450200',
}

enum plusRepatSalesPipelineStages {
  INDICATIVE_QUOTE_SENT = '27052927',
  INVOICE_SENT = '27064343',
  PENDING = '112480623',
  IN_PRODUCTION = '112480624',
  READY_TO_SHIP = '147814047',
  SHIPPED = '112480625',
  DELIVERED = '147814057',
  COMPLETE = '112480626',
}

// follow the same for graduation funnel and plus repeat sales

type TDealStage =
  | plusRepatSalesPipelineStages
  | graduationFunnelPipelineStages
  | newPlusSalesPipelineStages

// Since we are interested in deals from three different pipleines, we have three stage IDs for every stage.
export const HUBSPOT_DEAL_STAGES: Record<
  Extract<TPlusOrderStatus, 'pending' | 'production' | 'shipped' | 'complete'>,
  TDealStage[]
> = {
  pending: [
    newPlusSalesPipelineStages.PENDING,
    graduationFunnelPipelineStages.PENDING,
    plusRepatSalesPipelineStages.PENDING,
  ],
  production: [
    newPlusSalesPipelineStages.IN_PRODUCTION,
    newPlusSalesPipelineStages.READY_TO_SHIP,
    graduationFunnelPipelineStages.IN_PRODUCTION,
    graduationFunnelPipelineStages.READY_TO_SHIP,
    plusRepatSalesPipelineStages.IN_PRODUCTION,
    plusRepatSalesPipelineStages.READY_TO_SHIP,
  ],
  shipped: [
    newPlusSalesPipelineStages.SHIPPED,
    plusRepatSalesPipelineStages.SHIPPED,
    graduationFunnelPipelineStages.SHIPPED,
  ],
  complete: [
    newPlusSalesPipelineStages.DELIVERED,
    newPlusSalesPipelineStages.COMPLETE,
    graduationFunnelPipelineStages.DELIVERED,
    graduationFunnelPipelineStages.COMPLETE,
    plusRepatSalesPipelineStages.DELIVERED,
    plusRepatSalesPipelineStages.COMPLETE,
  ],
}

// Deal stages that define that order is in a quote state
export const DRAFT_ORDER_DEAL_STAGES: TDealStage[] = [
  newPlusSalesPipelineStages.INVOICE_SENT,
  graduationFunnelPipelineStages.INVOICE_SENT,
  plusRepatSalesPipelineStages.INVOICE_SENT,
]

// Quote related
export const orderToQuotePayload = (
  order: IPlusOrder,
  lineItems,
  extraDetails
) => {
  const orderLineItemsToPayload = (lineItems) => {
    return lineItems?.map((item) => {
      const optionsFields = item.options
        ?.map((option) => {
          if (option?.key?.toLowerCase() !== 'quantity') {
            return {
              label: option?.key,
              value: option?.value,
              name: option?.key?.toLowerCase()?.replaceAll(' ', '_'),
            }
          }
          return null
        })
        .filter((option) => (option?.label || option?.name) && option?.value)

      return {
        fields: [
          ...optionsFields,
          {
            name: 'image_url',
            value: lineItems?.[0]?.image,
            label: 'Image URL',
          },
          {
            name: 'page_url',
            value: lineItems?.[0]?.url,
            label: 'Page URL',
          },
          {
            name: 'sku',
            value: lineItems?.[0]?.sku,
            label: 'SKU',
          },
          {
            name: 'product_name',
            value: lineItems[0]?.name,
            label: 'Product Name',
          },
          {
            name: 'product_slug',
            value: lineItems[0]?.name?.toLowerCase()?.replaceAll(' ', '-'),
            label: 'Product Slug',
          },
          {
            name: 'quantity',
            value: String(lineItems[0]?.quantity),
            label: 'Quantity',
          },
        ],
      }
    })
  }

  return {
    fields: [
      {
        name: 'line_items',
        label: 'Line Items',
        value: orderLineItemsToPayload(lineItems),
      },
      {
        name: 'parent_deal_id',
        value: order?.id,
        label: 'Parent Deal Id',
      },
      {
        name: 'extra_details',
        value: extraDetails,
        label: 'Extra details',
      },
    ],
    context: {
      hutk: Cookie.get(COOKIE_HUBSPOT),
    },
  }
}

// Quote related ends
export const mapDealStateToPlusOrderStatus = (dealStage): TPlusOrderStatus => {
  let orderStatus: TPlusOrderStatus = null

  if (DRAFT_ORDER_DEAL_STAGES.includes(dealStage)) {
    return 'awaiting payment'
  }

  for (let [key, value] of Object.entries(HUBSPOT_DEAL_STAGES)) {
    if (value.includes(dealStage)) {
      orderStatus = key as TPlusOrderStatus
    }
  }

  return orderStatus
}

const getReorderedLineItems = (lineItems: any) => {
  let freightLineItem = null
  const reorderedLineItems = []

  for (const item of lineItems) {
    if (!item.sku) {
      throw new Error(`Item ${item.name} does not have an sku`)
    }

    if (PLUS_FREIGHT_SKUS.includes(item.sku.trim())) {
      freightLineItem = item
      continue
    }

    reorderedLineItems.push(item)
  }

  if (freightLineItem) {
    reorderedLineItems.push(freightLineItem)
  }

  return reorderedLineItems
}

const getItemDescription = (item) => {
  return item?.properties?.description?.split('\n').join(' ') || ''
}

const getPlusLineItemOptions = (item) => {
  return [
    {
      key: 'Quantity',
      value: +item?.properties?.quantity,
    },

    ...(item?.properties?.description?.split('\n')?.map((propertyString) => {
      const [key, value] = propertyString?.split(':')

      return {
        key,
        value: value?.trim(),
      }
    }) || []),
  ]
}

export const hubspotLineItemsToOrderItems = (
  hubspotLineItems: IHubspotLineItem[],
  bigCommerceProducts: any[]
): IPlusLineItem[] => {
  const orderItems = hubspotLineItems?.map((item) => {
    const sku = item?.properties?.hs_sku?.trim()
    const isFreightSku = PLUS_FREIGHT_SKUS.includes(sku)
    const bigCommerceProduct = bigCommerceProducts.find((p) => p.sku === sku)

    if (!sku || !bigCommerceProduct || !item?.properties?.price) {
      Sentry.captureMessage(
        `plusOrders::hubspotLineItemsToOrderItems: missing data.`,
        {
          level: 'warning',
          contexts: {
            Item: {
              id: item?.id,
              sku,
              bigCommerceProduct: JSON.stringify(bigCommerceProduct),
              price: item?.properties?.price,
            },
          },
        }
      )
    }

    return {
      id: item?.id,
      orderType: 'Plus',
      timestamp: new Date(item.properties?.createdate)?.getTime(),
      image: item?.properties?.hs_images,
      addedDate: isoDateToTimestamp(item?.properties?.createdate),
      updatedDate: isoDateToTimestamp(item?.properties?.hs_lastmodifieddate),
      sku,
      productId: item?.properties?.hs_product_id,
      bigCommerceProduct: bigCommerceProduct
        ? {
            id: bigCommerceProduct.id,
            options: bigCommerceProduct.options,
          }
        : null,
      url: item?.properties?.hs_url,
      name: item?.properties?.name,
      quantity: 1,
      price: +item?.properties?.amount,
      isArchived: item?.archived,
      designWasApproved: true,
      previewLink: item?.properties?.artwork_url,
      isFreightSku,
      options: isFreightSku ? [] : getPlusLineItemOptions(item),
      description: isFreightSku ? getItemDescription(item) : '',
    }
  })

  // if the order has a freight item, put it at the end
  return getReorderedLineItems(orderItems)
}

export const hubspotDealToPlusOrder = ({
  deal,
  contact = null,
}: {
  deal: IHubspotDeal
  contact?: IHubspotContact
}): IPlusOrder => {
  const orderType = DRAFT_ORDER_DEAL_STAGES.includes(
    deal?.properties?.dealstage as TDealStage
  )
    ? 'Quote'
    : 'Plus'
  return {
    id: deal?.id,
    dealId: deal?.id,
    orderPageSlug:
      orderType === 'Quote'
        ? `/admin/orders/quote/${deal?.id}`
        : `/admin/orders/plus/${deal?.id}`,
    timestamp: new Date(
      deal?.properties?.closedate || deal?.properties?.createdate
    ).getTime(),
    type: orderType,
    shipTo: '',
    createdDate: isoDateToReadableDate(
      deal?.properties?.closedate || deal?.properties?.createdate
    ),
    price: deal?.properties?.amount,
    currency: deal?.properties?.deal_currency_code,
    totalItems: +deal?.properties?.hs_num_of_associated_line_items,
    archived: deal?.archived,
    titleImage: '/plus-order-icon.png',
    status: mapDealStateToPlusOrderStatus(deal?.properties?.dealstage),
    cin7SO: deal?.properties?.cin7_so,
    lineItems: [],
    xeroInvoiceId: deal?.properties?.xero_invoice_number,
    carrier: deal?.properties?.carrier,
    trackingNumber: deal.properties?.tracking_number,
    estimatedArrival:
      deal?.properties?.estimated_arrival || 'Not available yet',

    shippingAddress: {
      firstName:
        deal?.properties?.shipping_first_name || contact?.properties?.firstname,
      lastName:
        deal?.properties?.shipping_last_name || contact?.properties?.lastname,
      company: '',
      streetInfo: deal.properties.shipping_street_address,
      additionalStreetInfo: '',
      city: deal.properties.shipping_city,
      zip: deal.properties.shipping_postal_code,
      country: deal.properties.shipping_country,
      state: deal.properties.shipping_state_region,
      email: deal?.properties?.shipping_email || contact?.properties?.email,
      phone: deal?.properties?.shipping_phone || contact?.properties?.phone,
    },

    billingAddress: {
      firstName:
        deal?.properties?.billing_first_name || contact?.properties?.firstname,
      lastName:
        deal?.properties?.billing_last_name || contact?.properties?.lastname,
      company: '',
      streetInfo: deal?.properties?.billing_street_address,
      additionalStreetInfo: '',
      city: deal?.properties?.billing_city,
      zip: deal?.properties?.billing_postal_code,
      country: deal?.properties?.billing_country,
      state: deal?.properties?.shipping_state_region,
      email: deal?.properties?.billing_email || contact?.properties?.email,
      phone: deal?.properties?.billing_phone || contact?.properties?.phone,
    },
    bcOrderId: deal?.properties?.big_commerce_order_id,
  }
}

const HUBSPOT_LEGACY_PIPELINE = '24804935'

export const getPlusOrders = async ({
  hubspotContact,
  ordersPerPage,
  page,
}: {
  hubspotContact: any
  ordersPerPage: number
  page: number
}): Promise<IPlusOrder[]> => {
  const hubspotDeals: IHubspotDeal[] =
    (
      await fetchHubspotDealByProperties({
        propertyFilters: [
          {
            propertyName: 'dealstage',
            operator: 'IN',
            values: Object.values(HUBSPOT_DEAL_STAGES)?.flat(),
          },
          {
            propertyName: 'associations.contact',
            operator: 'EQ',
            value: hubspotContact?.id,
          },
          {
            propertyName: 'pipeline',
            operator: 'NEQ',
            value: HUBSPOT_LEGACY_PIPELINE,
          },
        ],
        limit: ordersPerPage,
        after: (page - 1) * ordersPerPage,
      })
    ).results || []

  return (
    hubspotDeals
      .map((deal) =>
        hubspotDealToPlusOrder({
          deal,
          contact: hubspotContact,
        })
      )
      // No need to display plus deal if it has a corresponding BC order ID
      .filter((x) => x && !x.bcOrderId)
  )
}

export const getDraftPlusOrders = async ({
  hubspotContact,
  ordersPerPage,
  page,
  sorts = [],
}: {
  hubspotContact: any
  ordersPerPage: number
  page: number
  sorts?: Array<any>
}): Promise<IPlusOrder[]> => {
  const hubspotDeals: IHubspotDeal[] =
    (
      await fetchHubspotDealByProperties({
        propertyFilters: [
          {
            propertyName: 'dealstage',
            operator: 'IN',
            values: DRAFT_ORDER_DEAL_STAGES,
          },
          {
            propertyName: 'associations.contact',
            operator: 'EQ',
            value: hubspotContact?.id,
          },
          {
            propertyName: 'pipeline',
            operator: 'NEQ',
            value: HUBSPOT_LEGACY_PIPELINE,
          },
        ],
        limit: ordersPerPage,
        after: (page - 1) * ordersPerPage,
        sorts,
      })
    ).results || []

  return hubspotDeals
    .map((deal) =>
      hubspotDealToPlusOrder({
        deal,
        contact: hubspotContact,
      })
    )
    .filter((x) => x && !x.bcOrderId)
}
